import React, { useState, useEffect } from "react"
import ImageMeta from "../ImageMeta"
import CenterColumn from "../Bulma/CenterColumn"
import MarkdownViewer from "@components/MarkdownViewer"

import { Placeholder } from "cloudinary-react"

export default function ImageStrip({ images }) {
  const [mobilePhotoIndex, setMobilePhotoIndex] = useState()

  useEffect(() => {
    function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max))
    }

    let rand = getRandomInt(images.length)
    setMobilePhotoIndex(rand)
  })

  return (
    <div className="image-strip clearfix">
      {images.map((image, i) => {
        return (
          <>
            <div
              // data-aos-duration="1200"
              // data-aos="fade-up"
              key={image}>
              <ImageMeta
                cloudName="nuvolum"
                className={i === mobilePhotoIndex ? "" : "is-hidden-mobile"}
                key={image}
                publicId={image}
                width={1000}
                responsive={false}>
                <Placeholder type="blur"></Placeholder>
              </ImageMeta>
            </div>
          </>
        )
      })}
    </div>
  )
}
